<template>
  <div>
    <div class='container' v-if="device === 0">
    <h3 style="margin-top:50px">Oops! 開啟Cookie，即可進入本站</h3>
      <div style="height:600px; margin-top:50px">
      <img :src="imgsrc">
      </div>
      <h3>{{text}}</h3>
    <b-button v-if="step > 1" @click="stepMinus" style="margin:10px">上一步</b-button><b-button v-if="step < 4" @click="stepPlus">下一步</b-button><b-button v-if="step === 4" @click="backIndex">進入本站</b-button>
    </div>
    <div class='container' v-if="device === 1">
    <p style="margin-top:10px;">Oops! 允許彈出式視窗，即可進入本站</p>
      <div style="margin-top:10px">
      <img :src="imgsrc" width="300px" height="500px">
      </div>
      <p style="text-align:left; padding:15px" >{{text}}</p>
    <b-button v-if="step > 1" @click="stepMinus" style="margin:10px">上一步</b-button><b-button v-if="step < 3" @click="stepPlus">下一步</b-button><b-button v-if="step === 3" @click="backIndex">進入本站</b-button>
    </div>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import i18n from '../lang/lang'
import errorimgone from '../image/errorstepone.jpg'
import errorimgtwo from '../image/errorsteptwo.jpg'
import errorimgthree from '../image/errorstepthree.jpg'
import errorimgfour from '../image/errorstepfour.jpg'
import cellerrorone from '../image/cellerrorone.jpg'
import cellerrortwo from '../image/cellerrortwo.jpg'
import cellerrorthree from '../image/cellerrorthree.jpg'
export default {
  name: 'cookieerror',
  components: {
    BButton
  },
  data () {
    return {
      step: 1,
      errortext: '',
      imgsrc: errorimgone,
      device: 0
    }
  },
  computed: {
    text () {
      this.changeText(this.step)
      return this.errortext
    }
  },
  methods: {
    stepPlus () {
      if (this.step < 4) {
        this.step++
      }
    },
    stepMinus () {
      if (this.step > 1) {
        this.step--
      }
    },
    changeText (step) {
      if (this.device === 0) {
        switch (step) {
          case 1:
            this.errortext = '點選右上chorme管理工具，並選擇設定'
            this.imgsrc = errorimgone
            break
          case 2:
            this.errortext = '進入後選擇隱私權和安全性'
            this.imgsrc = errorimgtwo
            break
          case 3:
            this.errortext = '點選Cookie 和其他網站資料'
            this.imgsrc = errorimgthree
            break
          case 4:
            this.errortext = '勾選允許所有 Cookie , 並點擊進入本站後重新整理'
            this.imgsrc = errorimgfour
        }
      } else {
        switch (step) {
          case 1:
            this.errortext = '點選右下chorme管理工具，並選擇設定'
            this.imgsrc = cellerrorone
            break
          case 2:
            this.errortext = '進入後選擇內容設定'
            this.imgsrc = cellerrortwo
            break
          case 3:
            this.errortext = '將封鎖彈出式視窗開啟， 並點擊進入本站後重新整理'
            this.imgsrc = cellerrorthree
            break
        }
      }
    },
    backIndex () {
      const query = {
        finish: true,
        firstTime: true
      }
      this.$router.push({ path: `/${i18n.locale}/realtime`, query })
      window.location.reload()
    }
  },
  mounted () {
    this.device = (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) ? 1 : 0
  }
}
</script>
<style lang="scss" scoped>
  .container{
    width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  @media screen and (max-width: 1201px) {
    .container{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 100px;
  }
  }
</style>
